@tailwind components;
@tailwind utilities;
@tailwind base;

/* 
  This comes from postcss-normalize
  It's a shorthand for: @import "normalize.css" 
  We use @import-normalize to avoid collision with postcss-import
*/
@import-normalize;

html, body, #app {
  height: 100%;
}
