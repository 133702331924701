.wrapper {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-image: url(https://images.unsplash.com/photo-1484242857719-4b9144542727?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1280&q=80);
}

.form {
  max-width: 450px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  color: black;
  font-family: "Greycliff CF", sans-serif;
}
